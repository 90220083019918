// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component112Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_onClick ")
                }  
              };

const bloc2_0_3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc2_0_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "flex flex-row items-center bg-zinc-700 safearea w-screen bg-secondary_color bg-opacity-50 justify-between"}     > <img  id='bloc0_0'  className={ "rounded-xl m-6 h-16 w-16   object-cover"}  src="/profiles/ploy28/user.png"   alt="undefined" />
          <Link    to="/home"><div id='bloc0_1'  className={ "p-2 font-bold text-xl text-secondary_color"}    onClick = { bloc0_1_onClick } >{`BMN TAXI'S`} </div></Link>
          <FontAwesomeIcon  id='bloc0_2'  icon={ fa.faTaxi}      className={ "h-16 w-16 m-6 text-secondary_color"}  /></div><div id='bloc1'  className={ "w-screen flex flex-col pt-10 text-slate-500"}     > <div id='bloc1_0'  className={ "p-2 text-4xl font-semibold pb-10"}     >{`Mentions légales`} </div>
          <div id='bloc1_1'       > <div id='bloc1_1_0x0'  className={ "p-2 flex gap-1 flex-col w-full bg-white"}     > <div id='bloc1_1_0x0_0'  className={ "w-full p-2 bg-white font-bold flex flex-col items-start text-xl"}     >{`Mentions Légales BMN TAXIS`} </div>
              <div id='bloc1_1_0x0_1x0'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x0_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Éditeur du Site`} </div>
                <div id='bloc1_1_0x0_1x0_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Le site web www.bmn-taxis.fr est édité par App is You Entreprise, société immatriculée au Registre du Commerce et des Sociétés sous le numéro Beziers B 929 433 092.
        
        Directeur de la publication : HOTON Philippe
        Contact : contact@appisyou.com`} </div></div>
      <div id='bloc1_1_0x0_1x1'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x1_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{` Hébergement du Site`} </div>
                <div id='bloc1_1_0x0_1x1_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Le site est hébergé par Alwaysdata, une société spécialisée dans l'hébergement de sites web.
        Adresse : 91 rue du Faubourg Saint-Honoré, 75008 Paris, France
        Téléphone : +33 1 84 16 23 40
        Site web : www.alwaysdata.com`} </div></div>
      <div id='bloc1_1_0x0_1x2'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x2_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{` Propriété Intellectuelle`} </div>
                <div id='bloc1_1_0x0_1x2_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Le contenu du site www.bmn-taxis.fr textes, images, logos, vidéos, etc.) est la propriété exclusive de BMN Taxi's, sauf mention contraire. Toute reproduction, distribution, modification ou utilisation de ce contenu sans l'autorisation expresse de BMN Taxi's est interdite.`} </div></div>
      <div id='bloc1_1_0x0_1x3'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x3_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{` Protection des Données Personnelles`} </div>
                <div id='bloc1_1_0x0_1x3_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`BMN Taxi's s'engage à protéger la vie privée de ses utilisateurs conformément à la réglementation en vigueur. Les données collectées sur le site www.bmn-taxis.fr sont traitées conformément à notre Politique de Confidentialité.
        
        Responsable du traitement : HOTON Philippe
        Droits d'accès, de rectification et de suppression : Vous disposez d'un droit d'accès, de rectification, et de suppression de vos données personnelles, que vous pouvez exercer en contactant BMN Taxi's à l'adresse suivante : contact@appisyou.com`} </div></div>
      <div id='bloc1_1_0x0_1x4'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x4_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Conditions Générales d'Utilisation`} </div>
                <div id='bloc1_1_0x0_1x4_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`L'utilisation du site www.bmn-taxis.fr implique l'acceptation pleine et entière des conditions générales d'utilisation décrites dans cette section. BMN Taxi's se réserve le droit de modifier ces conditions à tout moment, les utilisateurs étant invités à les consulter régulièrement.`} </div></div>
      <div id='bloc1_1_0x0_1x5'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x5_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Limitation de Responsabilité`} </div>
                <div id='bloc1_1_0x0_1x5_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`BMN Taxi's s'efforce d'assurer l'exactitude des informations présentes sur le site, mais ne saurait être tenu responsable des erreurs ou omissions. L'utilisateur utilise le site à ses propres risques. BMN Taxi's ne peut être tenu responsable des dommages directs ou indirects, matériels ou immatériels, résultant de l'accès ou de l'utilisation du site.`} </div></div>
      <div id='bloc1_1_0x0_1x6'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     > <div id='bloc1_1_0x0_1x6_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Droit Applicable et Juridiction Compétente`} </div>
                <div id='bloc1_1_0x0_1x6_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Les présentes mentions légales sont régies par le droit français. En cas de litige, et après une tentative de résolution amiable, les tribunaux français seront seuls compétents.`} </div></div></div></div></div><div id='bloc2'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center text-secondary_color"}     > <div id='bloc2_0'  className={ "p-2 h grid grids-cols-1 md:grid-cols-2 gap-1 lg:grid-cols-3 pb-40"}     > <div id='bloc2_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_0_0'  className={ "text-xl font-bold"}     >{`Gares`} </div>
              <div id='bloc2_0_0_1'  className={ "whitespace-pre-line text-left"}     >{`Gare SNCF de LYON-PART-DIEU
      Gare SNCF de LYON-PERRACHE
      Gare SNCF de LYON-VAISE
      Gare routière de LYON-perrache
      Gare SNCF de BOURG EN BRESSE
      Gare de Genève Cornavin `} </div></div>
            <div id='bloc2_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_1_0'  className={ "font-bold text-xl"}     >{`Hôpitaux`} </div>
              <div id='bloc2_0_1_1'  className={ "whitespace-pre-line text-left"}     >{`Hôpital Édouard herriot 
      Centre Léon Bérard 
      Hôpital de la croix rousse 
      Hôpital saint-Joseph saint Luc 
      Hôpital privé jean mermoz
      Hôpital privé natecia 
      Hôpital Louis Pradel 
      Clinique du parc
      Hôpital neurologique pierre Wertheimer
      Hôpital Lyon Sud
      Hôpital Fleyriat
      Clinique Convert `} </div>
              <div id='bloc2_0_1_2'       > </div></div>
            <div id='bloc2_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_2_0'  className={ "font-bold text-xl"}     >{`Aéroports `} </div>
              <div id='bloc2_0_2_1'  className={ "whitespace-pre-line text-left"}     >{`Aéroport de Lyon saint éxupery
      Aéroport international de Genève 
      Aéroport de Dole-Jura`} </div></div>
            <Link    to="/legal"><div id='bloc2_0_3'  className={ "font-bold text-xl text-left p-4 underline"}    onClick = { bloc2_0_3_onClick } >{`Mentions légales`} </div></Link></div></div></>
  
}

export default Component112Page;
